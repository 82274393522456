<template>
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Import new line</span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('TRANSLATOR.LINE', { msg: 'LINE' }) }}</span>
      </h3>
      <!-- <div class="card-toolbar">
        <a href="#" class="btn btn-info font-weight-bolder font-size-sm">New Report</a>
      </div> -->
      <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <!-- <Dropdown3 :ConveyorId="id"></Dropdown3> -->
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <b-form-group id="input-group-1" label="Choose a company :" label-for="input-1">
        <b-form-select
          id="input-1"
          v-model="selectcompany"
          :options="companies"
          required
        ></b-form-select>
      </b-form-group>
      <!-- Excel File Upload -->
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
      <div class="mt-10">Selected file: {{ file ? file.name : '' }} / Selected company : {{ selectcompany }}</div>
      <b-row class="mt-10">
          <b-col xs="6" class="text-center">
            <b-button type="reset" class="mt-3 w-100" size="md" @click="reset">Reset</b-button>
          </b-col>
          <b-col xs="6" class="text-center">
            <b-button class="mt-3 w-100" variant="primary" disabled size="md" v-if="!selectcompany || !file">Upload disabled</b-button>
            <b-button type="submit" class="mt-3 w-100" variant="primary" size="md" @click="submit" v-if="selectcompany && file">Upload</b-button>
          </b-col>
      </b-row>

      <div class="mt-10 text-center" v-if="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <p class="text-6xl font-bold text-center text-gray-500 animate-pulse">
          Uploading Excel...
        </p>
        <!-- <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button> -->
      </div>
		<!-- Start of error alert -->
		<div class="mt-12 bg-red-50" v-if="error">
			<h3 class="px-4 py-1 text-4xl font-bold text-white bg-red-800">
				{{ error.title }}
			</h3>
			<p class="p-4 text-lg font-bold text-red-900">{{ error.message }}</p>
		</div>
		<!-- End of error alert -->
    </div>
    <!--end::Body-->
    <!-- <div class="card-footer">
    </div> -->
  </div>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  // name: "conveyor",
  components: {
    // AdvancedTableWidget2
    // KTWidget2,
    // ConveyorTable,
  },  
  data() {
    return {
      file: null,
      selectcompany: null,
      // companies: [{ text: 'Select a company', value: null }, { text: 'Coca-Cola', value: 1 }, 'Tomatos', 'Corn'],
      companies: [],
      loading: false,
      error: null,
      isAdmin: JwtService.authorizeToken().isAdmin === 'True'
    }
  },
  methods: {
    // submit form handler
    submit: function() {
      // this.submitted = true;
      let formData = new FormData();
      formData.append('file', this.file);
      this.loading = true;
      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      axios.post('line/upload/' + this.selectcompany, formData, config)
      .then(() => {
          this.loading = false;
          // console.log("ok")
      }).catch(err => {
        throw err;
      })
    },
    reset: function() {
      this.selectcompany = null;
      this.file = null;
    },
    // API Call get all companies
    async fetchCompanies() {
      try {
        this.error = null;
        this.loading = true;
        // const url = 'http://localhost:63303/api/import';
        const response = await axios.get('import');
        const results = response.data;
        // console.log(results)
        this.companies = results.map(row => ({
          value: row.companyId,
          text: row.companyName
        }));
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          this.error = {
            title: "Server Response",
            message: err.message
          };
        } else if (err.request) {
          // client never received a response, or request never left
          this.error = {
            title: "Unable to Reach Server",
            message: err.message
          };
        } else {
          // There's probably an error in your code
          this.error = {
            title: "Application Error",
            message: err.message
          };
        }
      }
      this.loading = false;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Admin > Import" }]);
    this.fetchCompanies();
    if (!this.isAdmin) {
      this.$router.push({ name: "login" });
    }
  }
};
</script>
